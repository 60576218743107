import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import ContentfulForm from './ContentfulForm';
import SalesAreaSearch from './SalesAreaSearch';
import LianaMailListForm from './LianaMailListForm';
import AlertNotification from './AlertNotification';
import { AllParcelWidgets } from './widgets';
import { Box } from 'theme-ui';
import TicketSearch from './TicketSearch';
import { TrackBySearch } from './TrackShipment';
import Accordion from './Accordion';
import { useTranslate } from '../utils/getLanguage';
import MultiParcelWidgets from './widgets/MultiParcelWidgets';
import BookingWidgetWide from './widgets/BookingWidgetWide';

const ScriptComponent = ({ css, id, src }) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <>
      <Helmet>
        {css && <link rel="stylesheet" type="text/css" href={css} />}
        <script type="text/javascript" src={src} />
      </Helmet>
      <div id={id}></div>
    </>
  );
};

const IFrameComponent = ({ id, style, ...rest }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <Box sx={{ my: 4 }}>
      <iframe style={style} {...rest}></iframe>
      <div id={id}></div>
    </Box>
  );
};

const ContentfulComponent = ({ template, title, data = {}, body, locale, assets, sxx, ...rest }) => {
  const translate = useTranslate();
  const selectComponent = template => {
    switch (template) {
      case 'LianaMailerForm':
        return <LianaMailListForm />;
      case 'Form':
        return <ContentfulForm title={title} data={data} {...rest} />;
      case 'Script':
        return <ScriptComponent {...data} />;
      case 'SalesAreaSearch':
        return <SalesAreaSearch {...data} assets={assets} />;
      case 'TicketSearch':
        return <TicketSearch {...data} assets={assets} />;
      case 'Notification':
        return <AlertNotification sxx={sxx} body={body} locale={locale} />;
      case 'ParcelWidget':
        return <AllParcelWidgets locale={locale} sxx={sxx} showHeader={false} center={true} />;
      case 'MultiParcelWidget':
        return <MultiParcelWidgets sxx={sxx} center={true} />;
      case 'IFrame':
        return <IFrameComponent {...data} />;
      case 'ParcelTracking':
        return (
          <Accordion
            title={translate('tracking.search.description2')}
            sx={{
              bg: 'white',
              border: '2px solid',
              borderColor: 'border',
              borderRadius: '8px',
              px: 3,
              py: 2,
              my: 4,
              outline: 0,
            }}
            contentSx={{ color: undefined, '*:first-of-type': undefined, '*:last-of-type': undefined }}
            disableOnKeyDown={true}
          >
            <TrackBySearch inlineTracking={true} />
          </Accordion>
        );
      case 'TicketWidget':
        return <BookingWidgetWide sxx={sxx} />;
      default:
        console.warn('Unknown component:', template);
        return null;
    }
  };
  return <>{selectComponent(template)}</>;
};

export default ContentfulComponent;
