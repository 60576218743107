/** @jsx jsx */
import React, { useMemo } from 'react';
import { jsx, Box, Flex } from 'theme-ui';
import moment from 'moment';
import { useLanguage, useTranslate } from '../utils/getLanguage';
import { capitalize } from '../utils/string';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { Button } from '../components';

const combineWeekdays = (listOfDays, propGetter, language, strFormatter) => {
  const weekdaysFi = ['Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai', 'Sunnuntai'];
  const weekdaysSv = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];
  const weekdaysEn = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const getDayIndex = obj =>
    weekdaysFi.indexOf(obj.weekDay) + 1 ||
    weekdaysSv.indexOf(obj.weekDay) + 1 ||
    weekdaysEn.indexOf(obj.weekDay) + 1 ||
    0;
  const sorted = listOfDays.sort((a, b) => {
    const aIndex = getDayIndex(a);
    const bIndex = getDayIndex(b);
    return aIndex - bIndex;
  });

  const slots = [];
  sorted.forEach(day => {
    const weekday = getDayIndex(day) || day.weekDay;
    const value = propGetter(day);
    let slot = slots.find(s => s.value === value);
    if (!slot) {
      slot = {
        value,
        weekdays: [weekday],
      };
      slots.push(slot);
    } else {
      slot.weekdays.push(weekday);
    }
  });

  return slots.map((slot, index) => {
    const dayStr = slot.weekdays.reduce((str, weekday, i) => {
      const isoWeekday =
        typeof weekday === 'number'
          ? capitalize(
              moment()
                .isoWeekday(weekday)
                .locale(language)
                .format('dd')
            )
          : weekday;

      if (i === 0) {
        return isoWeekday;
      } else if (slot.weekdays[i - 1] != weekday - 1) {
        return `${str}, ${isoWeekday}`;
      } else if (i === slot.weekdays.length - 1 || slot.weekdays[i + 1] !== weekday + 1) {
        return `${str}\u2013${isoWeekday}`;
      } else {
        return str;
      }
    }, '');
    return strFormatter(dayStr, slot.value);
  });
};

export const CutoffTimesAndTitle = ({ cutoffTimes }) => {
  const language = useLanguage();
  const translate = useTranslate();
  if (!cutoffTimes || cutoffTimes.length === 0) {
    return null;
  }
  const formatter = (day, time) => translate('servicePoints.parcelServices.before', { day, time });
  const lines = combineWeekdays(cutoffTimes, v => v.cutoffTime, language, formatter);

  const shownLines = lines ? lines.join(', ') : null;

  if (!shownLines) {
    return;
  }

  return (
    <Box>
      <p sx={{ mt: 4, mb: 1, color: 'black', fontWeight: 500 }}>
        {translate('servicePoints.parcelServices.cutoffTime')}
      </p>
      <p>{shownLines}</p>
    </Box>
  );
};

export const OpenTimes = ({ openTimes }) => {
  const language = useLanguage();
  if (!Array.isArray(openTimes) || openTimes.length === 0) return null;

  const formatter = (day, time) => `${day} ${time}`;
  const lines =
    typeof openTimes[0] === 'string'
      ? openTimes
      : combineWeekdays(openTimes, v => `${v.openTime}\u2013${v.closeTime}`, language, formatter);

  const shownLines = lines ? lines?.join(', ') : null;

  if (!shownLines) {
    return null;
  }
  return <p>{shownLines}</p>;
};

export const OpenTimesAndTitle = ({ openTimes }) => {
  const translate = useTranslate();
  if (!Array.isArray(openTimes)) {
    return null;
  }
  return (
    <Box>
      <p sx={{ mt: 4, mb: 1, color: 'black', fontWeight: 500 }}>{translate('servicePoints.openTimes')}</p>
      <OpenTimes openTimes={openTimes} />
    </Box>
  );
};

export const AlertRemarks = ({ alertRemarks }) => {
  if (!alertRemarks) {
    return null;
  }
  return (
    <Box
      sx={{
        maxWidth: 600,
        bg: '#F9EAEC',
        color: '#8C1021',
        borderRadius: 1,
        px: 3,
        py: 3,
        mt: 3,
      }}
    >
      {alertRemarks}
    </Box>
  );
};

export const NormalRemarks = ({ normalRemarks, showAlways = true, onSelect }) => {
  const { width } = useWindowDimensions();
  const translate = useTranslate();
  if (!normalRemarks) {
    return null;
  } else if (width < 850 && !showAlways) {
    return (
      <Button variant="plain" sx={{ textDecoration: 'underline' }} onClick={onSelect}>
        {translate('servicePoints.checkDetails')}
      </Button>
    );
  } else {
    return <Box sx={{ mt: 3, color: 'black' }}>{normalRemarks}</Box>;
  }
};

export const IBoxenNote = ({ isIBoxen }) => {
  const translate = useTranslate();
  if (!isIBoxen) {
    return null;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <p>{translate('servicePoints.bookFromApp')}</p>
    </Box>
  );
};

export const ServicesList = ({ services = [], officeType }) => {
  const translate = useTranslate();
  const servicesMap = {
    MAX_30KG_200CM: translate('servicePoints.services.max30Kg200Cm'),
    MAX_35KG_240CM: translate('servicePoints.services.max35Kg240Cm'),
    CONTRACT: translate('servicePoints.services.contract'),
    RETURN: translate('servicePoints.services.return'),
    PAID_SEND_AND_PICKUP: translate('servicePoints.services.paidSendAndPickup'),
    ACCESSIBLE: translate('servicePoints.services.accessible'),
  };

  const servicesList = useMemo(() => {
    if (officeType === 'EASYPACK') {
      return [];
    }

    const sList = [];
    const serviceCodes = (services || []).map(s => s.code);

    if (serviceCodes.includes('PARTNER_TERMINAL')) {
      sList.push([servicesMap.CONTRACT]);
      return sList;
    }

    if (serviceCodes.includes('P4')) {
      sList.push([servicesMap.MAX_35KG_240CM]);
    } else if (serviceCodes.includes('P2')) {
      sList.push([servicesMap.MAX_30KG_200CM]);
    }

    if (serviceCodes.includes('SL')) {
      sList.push([servicesMap.CONTRACT]);
    }

    const canSendAndReceive = serviceCodes.some(code => ['P1', 'P4'].includes(code));

    if (canSendAndReceive) {
      sList.push([servicesMap.PAID_SEND_AND_PICKUP]);
      sList.push([servicesMap.RETURN]);
    }

    return sList;
  }, [officeType, services]);

  return (
    <>
      {servicesList && servicesList.length ? (
        <Box sx={{ my: 3 }}>
          <ul sx={{ paddingLeft: 0, margin: 0, display: 'flex', flexDirection: 'column', gap: 2, listStyle: 'none' }}>
            {servicesList.map(([s, code]) => (
              <li
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3,
                  color: 'black',
                }}
              >
                <div sx={{ height: 11, width: 11, bg: 'primary', borderRadius: '100%', flexShrink: 0 }}></div>
                <div>
                  {s}
                  {code && <span sx={{ color: 'primary', fontWeight: 'bold', ml: 3 }}>{code}</span>}
                </div>
              </li>
            ))}
          </ul>
        </Box>
      ) : null}
    </>
  );
};

export const Address = ({ servicePoint, sxx, onClick }) => {
  if (!servicePoint) {
    return null;
  }
  return (
    <Flex>
      <Button
        variant="plain"
        onClick={onClick}
        sx={{
          p: 0,
          mt: 2,
          mb: 3,
          color: 'primary',
          fontWeight: 500,
          textDecoration: 'underline',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...sxx,
        }}
      >
        {capitalize(servicePoint.officeStreetAddress)}, {servicePoint.officePostalCode}{' '}
        {capitalize(servicePoint.officeCity)}
      </Button>
    </Flex>
  );
};
