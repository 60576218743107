/** @jsx jsx */
import React, { useMemo } from 'react';
import { jsx, Flex, Box } from 'theme-ui';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Map from './Map';
import ServicePointSearch from './ServicePointSearch';
import ServicePointList, { ServicePointSingleView } from './ServicePointList';
import {
  getServices,
  getServicePoints,
  getServicePointsFromArea,
  MAP_PRESELECTED_SERVICES,
  resetFilters,
} from '../state/servicePoints';
import getLanguage, { useTranslate } from '../utils/getLanguage';
import { getServicePointById } from '../utils/api';
import ServicePointFilters from './ServicePointFilters';
import { Button, Container } from '../components';
import { isEqual, sortBy, uniqBy } from 'lodash';
import useWindowDimensions from '../hooks/useWindowDimensions';

const UI_WIDTH = 440;

export default function MapAndFilters({ officeId, serviceCodes, height = 80, resultsMaxWidth = 1600 }) {
  const dispatch = useDispatch();
  const language = useSelector(state => getLanguage(state));
  const translate = useTranslate();
  const { servicePoints, servicePointSearchResults, services, language: loadedLanguage } = useSelector(
    state => state.servicePoints
  );
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [focusSelection, setFocusSelection] = useState(true);
  const [pointsFiltered, setPointsFiltered] = useState(servicePoints);
  const [pointsInView, setPointsInView] = useState(servicePoints);
  const [currentViewRect, setCurrentViewRect] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (services.length === 0 || language !== loadedLanguage) {
      dispatch(getServices(serviceCodes));
    }
  }, [dispatch, services.length, serviceCodes, language, loadedLanguage]);

  useEffect(() => {
    // Filter service points based on services
    let selectedServiceCodes = services.filter(s => s.selected).map(s => s.code);

    if (selectedServiceCodes.length > 0) {
      let pointsIncluded = [];
      const automatsIncluded = selectedServiceCodes.includes('EP');
      if (automatsIncluded) {
        const automats = servicePoints.filter(p => p.officeType === 'EASYPACK');
        pointsIncluded = [...pointsIncluded, ...automats];
      }

      const parcelPointCodes = ['P1', 'P2', 'P3', 'P35', 'P4', 'VAK', 'LQ'];
      const parcelPointsIncluded = selectedServiceCodes.some(c => parcelPointCodes.includes(c)) || false;
      if (parcelPointsIncluded) {
        const parcelPoints = servicePoints.filter(
          p => (p.services || []).some(s => parcelPointCodes.includes(s.code)) && p.officeType !== 'EASYPACK'
        );
        pointsIncluded = [...pointsIncluded, ...parcelPoints];
      }

      const contractCustomerPointsIncluded = selectedServiceCodes.includes('SL');
      if (contractCustomerPointsIncluded) {
        const contractCustomerPoints = servicePoints.filter(
          p => (p.services || []).some(s => s.code === 'SL') && (automatsIncluded || p.officeType !== 'EASYPACK')
        );
        pointsIncluded = [...pointsIncluded, ...contractCustomerPoints];
      }

      const terminalsIncluded = selectedServiceCodes.includes('TERMINAL');
      if (terminalsIncluded) {
        const terminals = servicePoints.filter(p => p.officeType === 'STATION');
        pointsIncluded = [...pointsIncluded, ...terminals];
      }

      const accessiblePointsIncluded = selectedServiceCodes.includes('ES');
      if (accessiblePointsIncluded) {
        const accessiblePoints = servicePoints.filter(p => p.services?.some(s => s.code === 'ES'));
        pointsIncluded = [...pointsIncluded, ...accessiblePoints];
      }

      pointsIncluded = uniqBy(pointsIncluded, 'officeCode');
      setPointsFiltered(pointsIncluded);
    } else {
      setPointsFiltered(servicePoints);
    }
  }, [services, servicePoints]);

  useEffect(() => {
    if (width < 800) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  }, [width]);

  useEffect(() => {
    // Filter out service points that are not on view
    if (currentViewRect) {
      const { left, right, top, bottom } = currentViewRect;
      const pointsInView = pointsFiltered.filter(office => {
        const { latitude, longitude } = office;
        return left < longitude && right > longitude && top > latitude && bottom < latitude;
      });
      setPointsInView(pointsInView);
    } else {
      setPointsInView(pointsFiltered);
    }
  }, [pointsFiltered, currentViewRect]);

  async function setServicePointAndFocus(servicePoint, focus) {
    setFocusSelection(focus);
    if (servicePoint) {
      const url = new URL(window.location);
      url.searchParams.set('id', servicePoint.officeCode);
      window.history.replaceState({}, '', url);
    }
    if (!servicePoint || language === 'fi') {
      setSelectedPoint(servicePoint);
    } else {
      const localizedSP = (await getServicePointById(servicePoint.officeCode, undefined, language))[0];
      setSelectedPoint(localizedSP);
    }
  }

  useEffect(() => {
    if (officeId) {
      (async () => {
        const sp = (await getServicePointById(officeId, undefined, language))[0];
        setSelectedPoint(sp);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPointsFromArea(viewRect) {
    setFocusSelection(false);
    dispatch(getServicePointsFromArea(viewRect));
  }

  const addressFound = useCallback(
    result => {
      dispatch(getServicePoints(result.PostalCode, result.Street, serviceCodes));
    },
    [dispatch, serviceCodes]
  );

  const handleResetFilters = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  const [showFilters, setShowFilters] = useState(true);

  const selectedServices = useMemo(() => {
    return services.filter(s => s.selected)?.map(s => s.code);
  }, [services]);

  const canResetFilters = useMemo(() => {
    if (!selectedServices.length || !!selectedPoint) {
      return false;
    }
    return !isEqual(sortBy(MAP_PRESELECTED_SERVICES), sortBy(selectedServices));
  }, [selectedServices, selectedPoint]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'relative' }}>
        <Map
          height={height}
          paddingLeft={UI_WIDTH}
          focus={focusSelection}
          offices={pointsFiltered}
          officesFocusGroup={servicePointSearchResults}
          selectedOffice={selectedPoint}
          onSelectOffice={servicePoint => setServicePointAndFocus(servicePoint, false)}
          onAddressFound={officeId ? null : addressFound}
          onViewChanged={setCurrentViewRect}
          onNewView={getPointsFromArea}
        />
        {canResetFilters && (
          <Flex sx={{ width: '100%', justifyContent: 'center' }}>
            <Button sx={{ position: 'absolute', bottom: 0, mb: 4 }} onClick={handleResetFilters}>
              {translate('servicePoints.disableFilters')}
            </Button>
          </Flex>
        )}
      </Box>
      <Box
        sx={{
          position: ['relative', null, 'absolute'],
          width: ['auto', null, `${UI_WIDTH}px`],
          top: [0, null, 4],
          bottom: [null, null, 4],
          left: [0, null, 4],
          maxHeight: '400px',
        }}
      >
        {!selectedPoint && (
          <Flex
            sx={{
              flexDirection: 'column',
              px: 3,
              pt: 3,
              mt: [3, null, null, 0],
              mx: [3, null, null, 0],
              bg: 'white',
              boxShadow: 'dropdown',
              borderRadius: [0, 1],
              overflowY: 'hidden',
              maxHeight: '100%',
            }}
          >
            <ServicePointSearch
              hideFilters={!!selectedPoint}
              setShowFilters={setShowFilters}
              onSearch={() => setServicePointAndFocus(null, true)}
            />

            <ServicePointFilters services={services} showFilters={showFilters} setShowFilters={setShowFilters} />
          </Flex>
        )}
      </Box>
      <Container sx={{ maxWidth: resultsMaxWidth, py: [2, 3, null, 4] }}>
        {selectedPoint ? (
          <ServicePointSingleView servicePoint={selectedPoint} onGoBack={() => setSelectedPoint(null)} />
        ) : (
          <>
            <ServicePointList
              viewRect={currentViewRect}
              servicePoints={pointsInView}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              priorityPoints={servicePointSearchResults}
              onSelect={setServicePointAndFocus}
            />
          </>
        )}
      </Container>
    </Box>
  );
}
