/** @jsx jsx */
import { jsx, Styled, Flex, Box } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { ParcelWidget } from '.';
import { getTranslate } from 'react-localize-redux';
import { useEffect, useState } from 'react';
import * as api from '../../utils/api';
import AlertNotification from '../AlertNotification';

export default ({ locale, sxx, showHeader = true, center = true }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await api.getProducts();
      setProducts(response);
    })();
  }, []);

  const data = useStaticQuery(dataQuery);
  const productNote = data?.allContentfulComponent?.edges?.find(e => e?.node?.node_locale?.startsWith(locale));

  if (!data || !data.allContentfulPageSection) {
    return null;
  }

  let liftups = data.allContentfulPageSection.edges.find(e => e.node.node_locale.startsWith(locale));
  if (!liftups) {
    liftups = data.allContentfulPageSection.edges.find(e => e.node.node_locale === 'fi-FI');
  }
  if (!liftups) {
    return null;
  }

  const { content } = liftups.node;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: ['flex-start', center ? 'center' : null],

        ...sxx,
      }}
    >
      {showHeader && <Styled.h2 sx={{ variant: 'text.title', mt: 0 }}>{translate('sendInSmallMoney')}</Styled.h2>}
      <Flex
        sx={{
          flexDirection: ['column', null, null, 'row'],
          justifyContent: center && 'center',
          overflowX: 'auto',
          maxWidth: '100%',
          width: ['100%', null],
          scrollSnapType: 'x mandatory',
          mb: 2,
          mt: 3,
          gap: 2,
          MsOverflowStyle: '-ms-autohiding-scrollbar',
        }}
      >
        {content.map(p => (
          <ParcelWidget key={p.id} {...p} productData={products} />
        ))}
      </Flex>
      {productNote && productNote?.node?.body && (
        <Box sx={{ px: [0, null, null, 26], width: '100%', my: 1 }}>
          <AlertNotification body={productNote?.node?.body} locale={locale} />
        </Box>
      )}
    </Flex>
  );
};

export const dataQuery = graphql`
  query {
    allContentfulPageSection(filter: { contentType: { eq: "Product" } }) {
      edges {
        node {
          node_locale
          content {
            ... on ContentfulProduct {
              id
              name
              maxSize
              price
              productId
              countryCode
              productType
            }
          }
        }
      }
    }
    allContentfulComponent(filter: { slug: { eq: "product-note" }, template: { eq: "Notification" } }) {
      edges {
        node {
          node_locale
          ...componentFields
        }
      }
    }
  }
`;
