/** @jsx jsx */
import React from 'react';
import { jsx, Styled, Grid, Container, Box, Button, useThemeUI } from 'theme-ui';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { isBrowser } from '../utils';
import * as analytics from '../utils/analytics';
import { readableColor } from 'polished';
import { useLanguage } from '../utils/getLanguage';

const WideImage = ({ backgroundColor, backgroundImage }) => {
  const { height, width } = useWindowDimensions();
  const aspectRatio = Math.max(1.5, Math.min((width / height) * 1.8, 2.5));

  return (
    <Box
      sx={{
        height: '100%',
        background: backgroundColor,
      }}
    >
      {isBrowser ? (
        <Img
          fluid={{ ...backgroundImage.fluid, aspectRatio }}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
          sx={{
            // maxWidth: 1440,
            mx: 'auto',
            maxHeight: 560,
          }}
          /*
      sx={{
        bg: overlayColor,
        img: {
          mixBlendMode: 'screen',
        },
      }} // */
        />
      ) : null}
    </Box>
  );
};

export default ({ style, title, backgroundImage, backgroundColor, accentColor, cta, theme, ...props }) => {
  const { theme: themeUI } = useThemeUI();
  const lang = useLanguage();
  const overlayColor =
    theme === 'green'
      ? themeUI.colors.greenDark
      : theme === 'dark'
      ? themeUI.colors.secondary
      : themeUI.colors.secondary;
  if (theme === 'dark' && !backgroundColor) {
    backgroundColor = themeUI.colors.secondary;
  } else if (theme === 'green' && !backgroundColor) {
    backgroundColor = themeUI.colors.greenDark;
  } else if (theme === 'mh.se' && !backgroundColor) {
    backgroundColor = themeUI.colors.secondary;
  } else if (theme === 'blue' && !backgroundColor) {
    backgroundColor = themeUI.colors.primary;
  }

  const handleClick = () => {
    const targetPath =
      cta.url || (cta.page.parentPage ? `/${cta.page.parentPage.slug}/${cta.page.slug}` : `/${cta.page.slug}`);
    analytics.heroClickEvent(title, targetPath);
    if (cta.url) isBrowser && window.location.assign(cta.url);
    return navigate(targetPath);
  };

  let contrastColor;
  try {
    contrastColor = readableColor(backgroundColor, '#001E60', 'white');
  } catch {
    contrastColor = '#001E60';
  }

  let formattedTitle = title;
  if (accentColor && title.includes('*')) {
    const parts = title.split('*');
    formattedTitle = parts.map((p, i) => {
      return (
        <span key={i} sx={{ color: i % 2 === 1 ? accentColor : 'inherit' }}>
          {p}
        </span>
      );
    });
  }

  let ctaTitle = (cta && cta.title) || '';
  if (accentColor && ctaTitle.includes('*')) {
    const parts = ctaTitle.split('*');
    const lastI = parts.length - 1;
    ctaTitle = parts.map((p, i) => {
      return (
        <span key={i} sx={{ color: i % 2 === 1 ? accentColor : 'inherit' }}>
          {p}
          {i === lastI && <> &rarr;</>}
        </span>
      );
    });
  } else {
    ctaTitle = <>{ctaTitle} &rarr;</>;
  }

  if (style === 'wide') {
    return <WideImage backgroundColor={backgroundColor} backgroundImage={backgroundImage} />;
  }

  return (
    <Box
      sx={{
        height: '100%',
        background: backgroundColor,
        pt: [0, 0, 0, 4],
        pb: 4,
      }}
    >
      <Container>
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
            gridGap: [2, 2, 2, 4],
            alignItems: 'center',
            cursor: cta ? 'pointer' : 'default',
          }}
          {...props}
          {...(cta && {
            onClick: () => handleClick(),
          })}
        >
          {backgroundImage && (
            <Img
              fluid={{ ...backgroundImage.fluid, aspectRatio: 3 / 2 }}
              objectFit="cover"
              objectPosition="50% 50%"
              alt=""
              sx={{
                order: [0, null, null, 1],
                //height: [280, 320, 400, null, 480],
                //pb: 0,
                borderRadius: 1,
                mx: [-3, null, null, 0],
                bg: overlayColor,
                /*
                img: {
                  mixBlendMode: 'screen',
                }, //*/
              }}
            />
          )}
          <Box sx={{ order: [1, null, null, 0], my: 1 }} lang={lang || 'fi'}>
            {title && (
              <Styled.h1
                sx={{
                  m: 0,
                  color: contrastColor,
                  fontSize: [4, 4, null, 48],
                }}
              >
                {formattedTitle}
              </Styled.h1>
            )}
            {cta && (
              <Button
                as={cta.page ? Link : 'a'}
                variant="plain"
                sx={{
                  py: [1, 1, 1, 2],
                  mt: [2, 2, 2, 3],
                  fontSize: [2, 3],
                  color: contrastColor,
                  ':hover': {
                    opacity: 0.8,
                    color: contrastColor,
                  },
                }}
                to={
                  cta.page
                    ? cta.page.parentPage
                      ? `/${cta.page.parentPage.slug}/${cta.page.slug}`
                      : `/${cta.page.slug}`
                    : cta.url
                }
              >
                {ctaTitle}
              </Button>
            )}
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};
