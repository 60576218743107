/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { useState, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { isBrowser } from '../utils';
import { useEffect } from 'react';
import RichText from './RichText';
import uniqueId from 'lodash/uniqueId';
import { sendAccordionOpenedEvent } from '../utils/analytics';

const Accordion = ({
  title,
  content,
  variant,
  isExpanded,
  disableOnKeyDown = false,
  chevronColor = '#001E60',
  ...props
}) => {
  const hash = isBrowser ? window.location.hash.substr(1) : '';
  const matchLocationHash = hash === props.id;
  const [expanded, setExpanded] = useState(!!isExpanded || false);
  const toggleExpanded = useCallback(() => {
    if (!expanded && isBrowser) {
      sendAccordionOpenedEvent(title);
    }
    setExpanded(e => !e);
  }, [expanded, title]);
  const handleKeyDown = useCallback(() => {
    if (!disableOnKeyDown) setExpanded(e => !e);
  }, [disableOnKeyDown]);
  const [id] = useState(uniqueId());
  useEffect(() => {
    if (isExpanded) {
      setExpanded(true);
    }
  }, [isExpanded]);

  const ref = useRef(null);
  useEffect(() => {
    if (matchLocationHash) {
      setExpanded(true);
      ref.current && ref.current.scrollIntoView({ block: 'center', inline: 'nearest' });
    }
  }, [matchLocationHash]);

  const faq = variant == 'faq' ? true : false;

  return (
    <Box
      sx={{
        bg: faq ? (matchLocationHash && expanded ? 'blueLight' : 'blueLighter') : 'transparent',
        borderRadius: faq ? 1 : 0,
        ':focus:not(:active)': {
          boxShadow: 'inputFocus',
        },
        mb: faq ? 1 : 0,
        borderBottom: faq ? 'none' : '1px solid',
        borderBottomColor: 'border',
      }}
      ref={ref}
      tabIndex="0"
      onKeyDown={handleKeyDown}
      {...props}
    >
      <Flex
        sx={{ cursor: 'pointer' }}
        role="button"
        id={`accordion-button-${id}`}
        aria-expanded={expanded}
        aria-controls={`accordion-panel-${id}`}
        onClick={toggleExpanded}
      >
        <Box
          as={motion.div}
          initial={false}
          sx={{
            flex: 'auto',
            fontWeight: 'medium',
            fontSize: faq ? 2 : 3,
            py: 12,
            px: faq ? 3 : 0,
            lineHeight: 'normal',
          }}
          children={title}
        />
        <motion.div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flex: 'none',
            marginTop: 16,
            marginRight: 16,
            width: 16,
            height: 16,
            transformOrigin: 'center center',
          }}
          key="arrow"
          variants={{
            open: { transform: 'rotate(-90deg)' },
            collapsed: { transform: 'rotate(90deg)' },
          }}
          initial="collapsed"
          animate={expanded ? 'open' : 'collapsed'}
        >
          <Box
            as="svg"
            width="11"
            height="16"
            viewBox="0 0 11 16"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M10.333 8c0 .441-.189.861-.52 1.153l-7.434 6.54a1.231 1.231 0 01-1.626-1.848l6.501-5.72a.167.167 0 000-.25L.753 2.155A1.231 1.231 0 012.379.307L9.81 6.845c.332.292.522.713.522 1.155z"
              fill={chevronColor}
              fillRule="nonzero"
            />
          </Box>
        </motion.div>
      </Flex>
      <motion.section
        key={content}
        initial="collapsed"
        animate={expanded ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto', display: 'block' },
          collapsed: {
            opacity: 0,
            height: 0,
            overflow: 'hidden',
            transitionEnd: {
              display: 'none',
            },
          },
        }}
        transition={{ duration: 0.3, ease: [0.04, 0.6, 0.2, 1] }}
      >
        <motion.div
          id={`accordion-panel-${id}`}
          aria-labelledby={`accordion-button-${id}`}
          variants={{ collapsed: { scale: 0.9 }, open: { scale: 1 } }}
          transition={{ duration: 0.15 }}
        >
          {content ? (
            <RichText content={content} locale={props.locale} />
          ) : (
            <Box
              sx={{
                color: 'black',
                px: faq ? 3 : 0,
                pb: 3,
                '*:first-of-type': { mt: 0 },
                '*:last-of-type': { mb: 0 },
                ...props.contentSx,
              }}
              children={props.children}
            />
          )}
        </motion.div>
      </motion.section>
    </Box>
  );
};

export default Accordion;
