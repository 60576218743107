import { useRef } from 'react';
import { capitalize } from './string';
import { isBrowser } from '../utils';

function getDataLayer() {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
}

export const setCookieConsent = (acceptedCategories, createEvent = false) => {
  getDataLayer().push({
    'Cookie Consent': acceptedCategories.join(','),
  });
  if (createEvent) {
    getDataLayer().push({
      event: 'setConsent',
      consent: acceptedCategories.join(','),
    });
  }
};

export const setUserId = user => {
  const { muserId } = user;
  if (muserId) {
    getDataLayer().push({
      event: 'loadUserData',
      userId: muserId, // must not be PII (Personally Identifiable Information), such as Email Address, Social Security Numbers, etc.
    });
  }
};

const mapOrderProducts = order => {
  const products = [];
  (order.shipments || []).forEach(sh => {
    products.push({
      name: sh.product.displayName, // Paketin / palvelun tyyppi
      id: sh.product.id, // Sisäinen ID tyypille jos löytyy, jos ei, voi käyttää samaa kuin yllä
      price: (sh.product.discountedPrice || sh.product.price).toFixed(2), // Yksittäisen paketin/palvelun hinta
      category: 'Paketti', // Paketti tai lisäpalvelu
      quantity: 1, // Lähetettävien pakettien määrä / tilattujen tuotteiden määrä
      dimension3: capitalize((sh.pickupPoint && sh.pickupPoint.officeCity) || sh.recipient.city), // Paketin kohdekaupunki
    });
    (sh.services || []).forEach(se => {
      products.push({
        name: se.displayName,
        id: se.displayName + '-' + sh.product.id, // Lisäpalvelun nimi + mihin tuotteeseen se on ostettu. Jos sama lisäpalvelu on kahdelle eri tuotteelle, siitä tulee kaksi eri lisäpalvelutuotetta
        price: (se.discountedPrice || se.price).toFixed(2),
        category: 'Lisäpalvelu',
        quantity: 1,
      });
    });
  });

  (order.seriesItems || []).forEach(si => {
    products.push({
      name: si.product.displayName, // Paketin / palvelun tyyppi
      id: si.product.id, // Sisäinen ID tyypille jos löytyy, jos ei, voi käyttää samaa kuin yllä
      price: ((si.product.discountedPrice || si.product.price) / si.product.quantity).toFixed(2), // Yksittäisen paketin/palvelun hinta
      category: 'Paketti', // Paketti tai lisäpalvelu
      quantity: si.product.quantity, // Lähetettävien pakettien määrä / tilattujen tuotteiden määrä
    });
  });

  return products;
};

let previousGAOrderId = isBrowser && localStorage.getItem('GAEventId');

export const sendOrderEvent = order => {
  if (previousGAOrderId === order.id) {
    // prevent double event when user refresh page or use browser back button
    return;
  }

  try {
    const event = {
      event: 'ecom-purchase-package',
      ecommerce: {
        purchase: {
          actionField: {
            id: order.id, // Tilausnumero. Jos tämä ei ole uniikki eri kauppojen välillä, voidaan käyttää esim. jotain etuliitettä (paketit-23456)
            affiliation: 'Paketit', // Kauppa (liput, paketit, matkakortit)
            revenue: order.price.toFixed(2), // Koko tilauksen loppusumma, verot mukana
            tax: (order.price - order.priceVat0).toFixed(2), // Verojen osuus erikseen
            shipping: '', // tyhjä
            coupon: (order.discount && order.discount.code) || '', // Jos käytössä ei ole alennuskoodia, jätetään tyhjäksi tai koko rivi pois
          },
          products: mapOrderProducts(order),
        },
      },
    };

    const dataLayer = getDataLayer();
    dataLayer.push(event);
    previousGAOrderId = order.id;
    localStorage.setItem('GAEventId', previousGAOrderId);
  } catch (err) {
    console.error(err);
  }
};

export const heroClickEvent = (title, destination) => {
  getDataLayer().push({
    event: 'heroClick',
    title,
    destination,
  });
};

export const sendFormEvent = eventName => {
  if (!eventName) {
    return;
  }
  getDataLayer().push({
    event: eventName,
  });
};

export const sendAccordionOpenedEvent = title => {
  if (!title) {
    return;
  }
  console.log('Accordion opened event', title);
  getDataLayer().push({
    event: 'accordion_opened',
    title,
  });
};

export const usePageCategory = pageCategory => {
  // useEffect is too late
  // pageCategory variable must be set before gatsby route change event
  const category = useRef(null);

  if (!isBrowser) {
    return;
  }

  if (category.current === null) {
    if (typeof pageCategory === 'object') {
      let parentPage = pageCategory;
      while (parentPage.parentPage) {
        parentPage = parentPage.parentPage;
      }
      pageCategory = parentPage.slug || 'not_set';
    }
    getDataLayer().push({
      pageCategory,
    });
    category.current = pageCategory;
  }
};

export const setPageCategory = (component, pageCategory) => {
  if (!isBrowser) {
    return;
  }

  if (!component.pageCategory) {
    if (typeof pageCategory === 'object') {
      let parentPage = pageCategory;
      while (parentPage.parentPage) {
        parentPage = parentPage.parentPage;
      }
      pageCategory = parentPage.slug || 'not_set';
    }
    getDataLayer().push({
      pageCategory,
    });
    component.pageCategory = pageCategory;
  }
};
